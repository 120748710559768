import { Autocomplete, TextField } from "@mui/material";
import { generatePkLabel, PrimaryKeyDt } from "components/collectionLongForm/widgets/ForeignKeyField";
import { FormEntryContext } from "pages/CollectionForm";
import { FunctionComponent, useContext } from "react";

interface ForeignKeyFieldProps{
    defaultValue: string;
    onChange: (value: string)=>void;
    required: boolean;
}

export const ForeignKeyField: FunctionComponent<ForeignKeyFieldProps> = ({
    defaultValue,
    required,
    onChange
}) => {

    const schemaContext = useContext(FormEntryContext)

    // console.log(props.formData);
    // console.log(props.state);
    // console.log();
    return (
            <Autocomplete
                options={schemaContext?.primaryKeys ?? []}
                isOptionEqualToValue={(option: PrimaryKeyDt, value) => option.nativeStr === value.nativeStr}
                getOptionLabel={generatePkLabel}
                disabled={!schemaContext}
                defaultValue={schemaContext?.primaryKeys.find((pk) => pk.nativeStr === defaultValue) ?? undefined}

                onChange={(_event, value) => onChange(value?.nativeStr ?? "")}
                renderInput={(params) => <TextField
                    {...params}
                    type='text'
                    required={required}
                />}
            />
    );
};