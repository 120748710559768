import { FieldProps } from '@rjsf/utils'
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useContext } from 'react';
import { FormEntryContext } from 'pages/CollectionForm';

export interface PrimaryKeyDt{
  nativeStr: string;
  label: string;
  title: string;
  created: Date;
  foreignKeys: string[];
}

export function generatePkLabel(pk: PrimaryKeyDt): string{
    return `${pk.label} - ${pk.title} ${pk.created.toLocaleDateString()} ${pk.created.toLocaleTimeString(undefined, { timeStyle: 'short' })} (${pk.nativeStr.substring(0, 8)})`;
}

export const ForeignKeyField = function (props: FieldProps) {
    
    const schemaContext = useContext(FormEntryContext)

    return (
        <FormControl variant='outlined'> 
          <Autocomplete
            options={schemaContext?.primaryKeys ?? []}
            isOptionEqualToValue={(option: PrimaryKeyDt, value) => option.nativeStr === value.nativeStr}
            getOptionLabel={generatePkLabel}
            disabled={!schemaContext}
            defaultValue={schemaContext?.primaryKeys.find((pk)=> pk.nativeStr===props.formData) ?? undefined} 
            
            onChange={(_event, value)=>props.onChange(value?.nativeStr)}
            renderInput={(params)=><TextField 
                {...params}
                id={props.name}
                type='text'
                required={props.required}  
                label={props.schema.title}
                />}
          />
        </FormControl>
    );
};