import { FunctionComponent, useState, useEffect, useContext, useRef, createContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { UserContext } from "components/user";
import { PageLayout, Wrapper } from "components/layout";
import { Text } from "components/text";
import { UserNeedsAuthentication } from "components/user";
import useCartaObjectStorage from "hooks/useCartaObjectStorage";
import { NavigationLink } from "components/navigation";
import { Button, ToggleButtonGroup, ToggleButton, IconButton } from "@mui/material";
//import QrCodeIcon from "components/icons/QrCodeIcon";
import QRCode from "react-qr-code";
import { QrCode as QrCodeIcon } from "@mui/icons-material";
import { CollectionLongForm } from "components/collectionLongForm/CollectionLongForm";
import { CollectionTableForm } from "components/collectionTableForm/CollectionTableForm";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { IFormSchemaResponse, NativeId, PrimaryKeyWithDate } from "data/types";
import { JSONSchema7 } from "json-schema";

interface FormEntryContextProps {
  primaryKeys: PrimaryKeyWithDate[];
}

const formLimit = 5;

export const FormEntryContext = createContext<FormEntryContextProps | null>(null);

const CollectionForm: FunctionComponent = () => {
  const [error, setError] = useState<string>();
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [connectionId, setConnectionId] = useState("");
  const [nativeId, setNativeId] = useState<NativeId | null>(null);
  const [schema, setSchema] = useState<IFormSchemaResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [viewMode, setViewMode] = useState<"longForm" | "table">("longForm");
  const location = useLocation();
  const history = useHistory();
  const qrcode = useRef<HTMLDivElement>(null);
  const cartaObjectStorage = useCartaObjectStorage();
  const [forms, setForms] = useState<any[]>([]);

  const openQrCode = () => {
    const qrWindow = window.open("", "", "width=600,height=400,top=0,left=0");
    if (!qrWindow) {
      return;
    }
    qrWindow.document.write(qrcode.current?.innerHTML ?? "");
    qrWindow.document.close();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _search = new URLSearchParams(location.search);
        const projectId = _search.get("projectId");
        const key = _search.get("key");
        const connectionId = _search.get("connectionId");
        const nativeId = _search.get("nativeId");
        const parentNativeId = _search.get("parentNativeId");

        let collectionProps;

        if (projectId) {
          setProjectId(projectId);
          if (key) {
            collectionProps = { projectId: projectId, key: key };
          } else if (connectionId && nativeId) {
            setNativeId(JSON.parse(nativeId));
            setConnectionId(connectionId);
            collectionProps = {
              projectId: projectId,
              connectionId: connectionId,
              node: { nativeId: JSON.parse(nativeId) },
              parentNode: {
                nativeId: parentNativeId ? JSON.parse(parentNativeId) : {},
              },
            };
          }
        }

        if (!collectionProps) {
          throw new Error("Not enough data to construct collection request.");
        }

        const _cartaObjectStorage = await cartaObjectStorage;
        const getProjectName = _cartaObjectStorage
          .listProjects()
          .then((projects) => projects.find((_project) => _project.projectId === projectId))
          .then((project) => setProjectName(project?.name ?? ""));

        const getResourceData = _cartaObjectStorage.getFormSchema(collectionProps).then((schema) => {
          setSchema(schema);
          setForms(schema.form_data ?? [undefined]); // Initialize with one empty form
        });

        await Promise.all([getProjectName, getResourceData]);
        setLoading(false);
      } catch (error) {
        setError(error instanceof Error ? error.message : "An unknown error occurred.");
        setLoading(false);
      }
    };

    fetchData();
  }, [location, cartaObjectStorage]);

  const handleFormAddition = () => {
    if (forms.length < formLimit) {
      setForms([...forms, undefined]);
    }
  };

  const handleFormDuplication = (index: number) => {
    if (forms.length < formLimit) {
      const formToDuplicate = forms[index];
      const duplicatedForm = JSON.parse(JSON.stringify(formToDuplicate)); // Deep copy to avoid reference issues
      setForms([...forms, duplicatedForm]);
    }
  };

  const handleFormRemoval = (index: number) => {
    const updatedForms = forms.filter((_, i) => i !== index);
    setForms(updatedForms);
  };

  const handleFormChange = (index: number, updatedData: any) => {
    const updatedForms = [...forms];
    updatedForms[index] = updatedData;
    setForms(updatedForms);
  };

  const handleSave = async () => {
    try {
      const _cartaObjectStorage = await cartaObjectStorage;
      if (!schema) {
        throw new Error("Attempting to save when no schema defined");
      }

      if (nativeId && forms.length === 1) {
        await _cartaObjectStorage.saveCollection({
          projectId,
          key: { id: schema.schema_key.id, version: schema.schema_key.version },
          connectionId,
          nativeId,
          formData: forms[0],
        });
      } else {
        for (const form of forms) {
          await _cartaObjectStorage.createCollection({
            projectId: projectId,
            key: { id: schema.schema_key.id, version: schema.schema_key.version },
            formData: form,
          });
        }
      }

      console.log("Forms saved successfully");
      history.push("/"); // Redirect to the home page
    } catch (error) {
      console.error("Error saving forms:", error);
      setError("An error occurred while saving the forms. Please try again.");
    }
  };

  const handleViewToggle = (event: React.MouseEvent<HTMLElement>, newViewMode: "longForm" | "table" | null) => {
    if (newViewMode) {
      setViewMode(newViewMode);
    }
  };

  if (loading) {
    return (
      <Text size="medium" padding="center">
        Loading form schema...
      </Text>
    );
  }

  if (error) {
    return (
      <Text color="error" size="small" padding="center">
        {error}
      </Text>
    );
  } else {
    return (
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", width: "100%" }}>
        <h2 style={{ margin: 4 }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <NavigationLink to={`/?projectId=${projectId}`}>
              {`${projectName}: ${schema?.schema?.title || ""}`}
            </NavigationLink>
            {nativeId ? null : (
              <>
                <div style={{ display: "none" }} ref={qrcode}>
                  <QRCode size={256} value={document.location.toString()} />
                </div>
                <IconButton
                  onClick={openQrCode}
                  style={{ backgroundColor: "#777", color: "white", marginLeft: "1rem" }}>
                  <QrCodeIcon />
                </IconButton>
              </>
            )}
          </div>
        </h2>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewToggle}
            aria-label="View mode"
            style={{ marginBottom: "1rem" }}>
            <ToggleButton value="longForm" aria-label="Long form view">
              Long Form View
            </ToggleButton>
            <ToggleButton value="table" aria-label="Table view">
              Table Entry View
            </ToggleButton>
          </ToggleButtonGroup>
          <IconButton
            aria-label="add blank form"
            onClick={handleFormAddition}
            style={{ backgroundColor: "#28a745", color: "white", marginLeft: "1rem" }}>
            <AddIcon />
          </IconButton>
        </div>
        <FormEntryContext.Provider value={schema ? { primaryKeys: schema.primaryKeys } : null}>
          {/* Render either the long form view or the table view */}
          {viewMode === "longForm"
            ? schema &&
              forms.map((form, index) => (
                <Accordion key={index} style={{ marginBottom: "1rem" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}>
                    <Typography variant="h6">Form {index + 1}</Typography>
                    <div style={{ marginLeft: "auto" }}>
                      <IconButton
                        aria-label="duplicate form"
                        onClick={() => handleFormDuplication(index)}
                        color="primary">
                        <ContentCopyIcon />
                      </IconButton>
                      <IconButton aria-label="remove form" onClick={() => handleFormRemoval(index)} color="secondary">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CollectionLongForm
                      projectId={projectId}
                      formData={form}
                      schema={schema.schema as JSONSchema7}
                      keyInfo={schema.key_info}
                      onChange={(data) => handleFormChange(index, data)}
                    />
                  </AccordionDetails>
                </Accordion>
              ))
            : schema && (
                <CollectionTableForm
                  projectId={projectId}
                  forms={forms}
                  schema={schema.schema}
                  onChange={(updatedForms) => setForms(updatedForms)}
                  onDuplicate={handleFormDuplication}
                  onRemove={handleFormRemoval}
                />
              )}
        </FormEntryContext.Provider>

        <Button
          onClick={handleSave}
          style={{ marginTop: "1rem", backgroundColor: "#28a745", color: "#fff" }}
          disabled={forms.length === 0}>
          Save Forms
        </Button>
      </div>
    );
  }
};

const CollectionFormPage: FunctionComponent = () => {
  const { authenticated } = useContext(UserContext);

  return (
    <PageLayout header footer>
      <Wrapper>{authenticated ? <CollectionForm /> : <UserNeedsAuthentication />}</Wrapper>
    </PageLayout>
  );
};

export default CollectionFormPage;
