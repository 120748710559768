import { FunctionComponent } from "react";
import Form from "@rjsf/mui";
import { IChangeEvent } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import validator from "@rjsf/validator-ajv8";
import { KeyInfo } from "data/types";
import { CartaWidgetFields } from "./widgets";

interface CollectionLongFormProps {
  projectId: string;
  formData: any;
  schema: JSONSchema7;
  keyInfo: KeyInfo;
  onChange: (data: any) => void;
}

export const CollectionLongForm: FunctionComponent<CollectionLongFormProps> = ({
  projectId,
  formData,
  schema,
  keyInfo,
  onChange,
}) => {
  const safeFormData = formData ?? undefined;

  const handleFormChange = (event: IChangeEvent<any>) => {
    onChange(event.formData);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "1rem",
        border: "1px solid #ddd",
        borderRadius: "8px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        padding: "1rem",
        backgroundColor: "#f9f9f9",
      }}>
      {/* Render the form using @rjsf/mui v5 for consistent MUI styling */}
      <Form
        schema={schema}
        formData={safeFormData}
        onChange={handleFormChange}
        validator={validator}
        fields={CartaWidgetFields}
        showErrorList={false}>
        {/* Render an empty fragment to remove the default submit button */}
        <></>
      </Form>
    </div>
  );
};

export default CollectionLongForm;
