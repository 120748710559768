import { FunctionComponent } from "react";
import { Route, Switch } from "react-router";
import { CollectionPage, CollectionFormPage, HelpPage, SignInPage, ForgotPasswordPage, ResetPasswordPage } from "pages";
import { UserWrapper } from "components/user";
import { Notifications } from "components/notifications";
// import { ThemeWrapper } from "components/theme";
import "styles/reset.css";
import "styles/globals.css";
import "styles/theme.css";
import "styles/app.css";

/**
 * The main app component which renders all other components.
 * Used to store routing information for each page.
 */
const App: FunctionComponent = () => {
  return (
    <Notifications>
      <UserWrapper>
        {/* <ThemeWrapper> */}
        <Switch>
          {/* Home page route. */}
          <Route exact path="/" component={CollectionPage} />

          {/* Help page route. */}
          <Route exact path="/help" component={HelpPage} />

          {/* Forms page route. */}
          <Route exact path="/collection" component={CollectionFormPage} />

          {/* Authentication page routes. */}
          <Route exact path="/signin" component={SignInPage} />
          <Route exact path="/signin/forgotpassword" component={ForgotPasswordPage} />
          <Route exact path="/signin/resetpassword" component={ResetPasswordPage} />
        </Switch>
        {/* </ThemeWrapper> */}
      </UserWrapper>
    </Notifications>
  );
};

export default App;
