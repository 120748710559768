import { FunctionComponent, useState, useEffect, useContext, useCallback } from "react";
import { useLocation } from "react-router";
import { Button } from "components/buttons";
import { Dropdown } from "components/dropdown";
import { UserContext } from "components/user";
import { PageLayout, Wrapper } from "components/layout";
import { UserNeedsAuthentication } from "components/user";
import { Text } from "components/text";
import { TreeViewComponent } from "components/treeview";
import useCartaObjectStorage from "hooks/useCartaObjectStorage";
import { IConnection, IProjectProps } from "data/types";
import { SampleBrowser } from "components/sampleBrowser";
import { LabelMap } from "data/Sample";

export interface IFormProps {
  title?: string;
  key: string;
}

const CollectionAuthenticated: FunctionComponent = () => {
  const [error, setError] = useState<string>();
  const [projects, setProjects] = useState<IProjectProps[]>([]);
  const [projectId, setProjectId] = useState<string>();
  const [forms, setForms] = useState<IFormProps[]>([]);
  const [samples, setSamples] = useState<LabelMap>();
  const [connections, setConnections] = useState<IConnection[]>([]);
  const [formsLoaded, setFormsLoaded] = useState<boolean>(false);
  const location = useLocation();
  const cartaObjectStorage = useCartaObjectStorage();

  const projectIdFromUrl = useCallback(() => new URLSearchParams(location.search).get("projectId"), [location]);

  useEffect(() => {
    (async () => {
      try {
        const _cartaObjectStorage = await cartaObjectStorage;
        const projectList = await _cartaObjectStorage.listProjects();

        setFormsLoaded(false);
        setProjects(projectList);
        const _projectIdFromUrl = projectIdFromUrl();
        if (_projectIdFromUrl) {
          setProjectId(_projectIdFromUrl);
        }

        if (projectList.length === 1) setProjectId(projectList[0].projectId);
      } catch (error) {
        if (error instanceof Error) setError(error.message);
        else setError("An unknown error occurred.");
      }
    })();
  }, [cartaObjectStorage, projectIdFromUrl]);

  useEffect(() => {
    (async () => {
      try {
        if (!projectId) return;

        const _cartaObjectStorage = await cartaObjectStorage;
        const _projectIdFromUrl = projectIdFromUrl();
        if (_projectIdFromUrl !== projectId) {
          window.history.pushState({}, "", `?projectId=${projectId}`);
        }
        setForms(await _cartaObjectStorage.listSchemas({ projectId }));
        setFormsLoaded(true);
        _cartaObjectStorage.listConnections({ projectId }).then((connectionsResult) => {
          setConnections(connectionsResult);
          _cartaObjectStorage
            .getSamples({ projectId: projectId, connectionId: connectionsResult[0].connectionId })
            .then((samples) => {
              setSamples(samples);
            });
        });
      } catch (error) {
        if (error instanceof Error) setError(error.message);
        else setError("An unknown error occurred.");
      }
    })();
  }, [projectId, cartaObjectStorage, projectIdFromUrl]);

  const handleExport = () => {};

  const enabledButtonStyle = {
    color: "white",
    backgroundColor: "#6c757d",
    borderColor: "#6c757d",
    padding: ".375rem .75rem",
  };
  

  return (
    <div style={{ display: "flex", flex: 1 }}>
      {!projectId && projects.length > 1 && (
        <>
          <Dropdown side="bottom-left">
            <Dropdown.Toggler caret>
              <Text size="medium">Select Project</Text>
            </Dropdown.Toggler>
            <Dropdown.Area>
              {projects.map((item: IProjectProps) => (
                <Dropdown.Item key={item.projectId} href={`/?projectId=${item.projectId}`}>
                  {item.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Area>
          </Dropdown>
          <Text color="error" size="small" padding="center">
            {error}
          </Text>
        </>
      )}
      {projectId && formsLoaded && (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flex: 1 }}>
          <div style={{ flex: 1, flexBasis: "50%" }}>
            <Text size="medium">Select Existing</Text>
            {connections.map((item: IConnection) => (
              <TreeViewComponent
                key={item.connectionId}
                connectionId={item.connectionId}
                node={item.node}
                projectId={projectId}
                forms={forms}
              />
            ))}
            <Button onClick={handleExport} style={enabledButtonStyle} disabled>
              Export All
            </Button>
            <Dropdown side="bottom-right">
              <Dropdown.Toggler caret>
                <Text size="medium">Start New</Text>
              </Dropdown.Toggler>
              <Dropdown.Area>
                {forms.map((item: IFormProps) => (
                  <Dropdown.Item key={item.key} href={`/collection?projectId=${projectId}&key=${item.key}`}>
                    {(item.title && item.title) || item.key.substring(0, item.key.lastIndexOf("."))}
                  </Dropdown.Item>
                ))}
              </Dropdown.Area>
            </Dropdown>
            <Text color="error" size="small" padding="center">
              {error}
            </Text>
          </div>
          {samples && connections ? (
            <SampleBrowser
              samples={samples}
              connection={{ projectId, connectionId: connections[0].connectionId }}
              style={{ flex: 1, flexBasis: "50%" }}></SampleBrowser>
          ) : (
            <></>
          )}
        </div>
      )}
      {projectId && !formsLoaded && ( error ? (<Text color="error" size="small" padding="center"> {error} </Text>) : (<Text size="medium">Loading...</Text>))}
    </div>
  );
};

const CollectionPage: FunctionComponent = () => {
  const { authenticated } = useContext(UserContext);

  return (
    <PageLayout header footer>
      <Wrapper>
        {authenticated ? (
          // By adding the key property, when the page is refreshed,
          // the state will be reset and all data will be fetched from scratch
          <CollectionAuthenticated key={Date.now()} />
        ) : (
          <UserNeedsAuthentication />
        )}
      </Wrapper>
    </PageLayout>
  );
};

export default CollectionPage;
