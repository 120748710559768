import { FunctionComponent } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableFormField } from "./fields";

interface CollectionTableFormProps {
  projectId: string;
  forms: any[];
  schema: any;
  onChange: (updatedForms: any[]) => void;
  onDuplicate: (index: number) => void;
  onRemove: (index: number) => void;
}

export const CollectionTableForm: FunctionComponent<CollectionTableFormProps> = ({
  projectId,
  forms,
  schema,
  onChange,
  onDuplicate,
  onRemove,
}) => {
  const handleInputChange = (index: number, section: string, field: string, value: any) => {
    const updatedForms = [...forms];
    updatedForms[index] = {
      ...updatedForms[index],
      [section]: {
        ...updatedForms[index][section],
        [field]: value,
      },
    };
    onChange(updatedForms);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {schema?.properties &&
              Object.keys(schema.properties).map(
                (section) =>
                  schema.properties[section]?.properties && (
                    <TableCell key={section} colSpan={Object.keys(schema.properties[section].properties).length}>
                      {schema.properties[section].title || section}
                    </TableCell>
                  )
              )}
            <TableCell>Actions</TableCell>
          </TableRow>
          <TableRow>
            {schema?.properties &&
              Object.keys(schema.properties).map(
                (section) =>
                  schema.properties[section]?.properties &&
                  Object.keys(schema.properties[section].properties).map((field) => (
                    <TableCell key={`${section}-${field}`}>
                      {schema.properties[section].properties[field].title || field}
                    </TableCell>
                  ))
              )}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {forms.map((form, index) => (
            <TableRow key={index}>
              {schema?.properties &&
                Object.keys(schema.properties).map(
                  (section) =>
                    schema.properties[section]?.properties &&
                    Object.keys(schema.properties[section].properties).map((field) => {
                      const property = schema.properties[section].properties[field];
                      const fieldValue = form[section]?.[field] ?? property.default ?? "";
                      
                      return (<TableFormField
                        key={`${index}-${section}-${field}`}
                        onChange={(value) => handleInputChange(index, section, field, value)}
                        property={property}
                        defaultValue={fieldValue}
                      />)
                    })
                )}
              <TableCell>
                <IconButton aria-label="duplicate form" onClick={() => onDuplicate(index)} color="primary">
                  <ContentCopyIcon />
                </IconButton>
                <IconButton aria-label="remove form" onClick={() => onRemove(index)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollectionTableForm;
