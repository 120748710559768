import { ViewWeek } from "@mui/icons-material";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { FunctionComponent, useCallback, useRef, useState } from "react";
import Barcode from "react-barcode";

interface PrimaryKeyFieldProps{
    defaultValue: string;
    onChange: (value: string)=>void;
    required: boolean;
}

const preventDefault = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
};

export const PrimaryKeyField: FunctionComponent<PrimaryKeyFieldProps> = ({
    defaultValue,
    required,
    onChange
})=>{
    
    const barcode = useRef<HTMLDivElement>(null);
    const [key, setKey] = useState<string>("");
    const handleClickShowBarcode = useCallback(() => {
        const qrWindow = window.open("", "", "width=600,height=400,top=0,left=0");
        if (!qrWindow) {
        return;
        }
        qrWindow.document.write(barcode.current?.innerHTML ?? "");
        qrWindow.document.close();
    }, []);

    return (<><OutlinedInput
        type='text'
        defaultValue={defaultValue}
        required={required} 
        onChange={(event)=>{onChange(event.target.value); setKey(event.target.value)}} 
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Print barcode"
              onClick={handleClickShowBarcode}
              onMouseDown={preventDefault}
              onMouseUp={preventDefault}
            >
              <ViewWeek />
            </IconButton>
          </InputAdornment>
          }/>
          <div style={{display: 'none'}} ref={barcode}>
            {key && <Barcode value={key}/>}
          </div></>
        )
}
