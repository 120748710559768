import { FunctionComponent } from "react";
import { JSONSchema7Definition } from 'json-schema'
import { TableCell, TextField } from "@mui/material";
import { PrimaryKeyField } from "./PrimaryKeyField";
import { ForeignKeyField } from "./ForeignKeyField";

interface CartaSchemaProperty{
    "&pk"?: boolean;
    "&fk"?: boolean;
}

export interface TableFormFieldProps {
    property: JSONSchema7Definition & CartaSchemaProperty;
    defaultValue: any;
    onChange: (value: any) => void
}

export const TableFormField: FunctionComponent<TableFormFieldProps> = ({
    property,
    defaultValue,
    onChange
}) => {

    if (typeof property === 'boolean') {
        return (<></>)
    }

    if(property["&pk"]){
        return (
            <TableCell>
                <PrimaryKeyField defaultValue={defaultValue} required={true} onChange={(value)=>onChange(value)}/>
            </TableCell>
        )
    }

    if(property["&fk"]){
        return (
            <TableCell>
                <ForeignKeyField defaultValue={defaultValue} required={true} onChange={(value)=>onChange(value)}/>
            </TableCell>
        )
    }

    return (
        <TableCell>
            <TextField
                defaultValue={defaultValue ?? ""}
                onChange={(e) => onChange(e.target.value)}
                fullWidth
            />
        </TableCell>
    )
}